import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const id = Math.floor(Math.random() * 1000);
  return (
    <Svg
      viewBox="0 0 28 28"
      fill="none"
      {...props}
    >
       <path
      d="M14 0C6.27287 0 0 6.27287 0 14C0 21.7271 6.27287 28 14 28C21.7271 28 28 21.7271 28 14C28 6.27287 21.7271 0 14 0ZM14.0868 24.5271C11.4279 24.5488 8.98605 23.5829 7.13023 21.9659C7.04341 21.8899 7.10853 21.7597 7.21705 21.7814C10.6357 22.4651 17.7333 22.7581 21.9984 14.9876C22.031 14.9333 21.955 14.8791 21.9116 14.9225C20.5767 16.4961 15.5953 21.6512 8.88837 19.6977C1.0093 17.4078 3.64651 10.2667 9.21395 12.4806C13.9783 14.3798 16.7891 15.1287 19.5132 13.9349C19.6326 13.8806 19.5783 13.7178 19.4589 13.7287C18.0915 13.8915 15.0419 13.8698 11.5147 11.2977C8.7907 9.33333 6.46822 9.12713 4.95969 9.31163C4.78605 9.33333 4.65581 9.14884 4.74264 8.9969C6.52248 5.70853 9.99535 3.47287 14 3.47287C15.5085 3.47287 16.9411 3.7876 18.2434 4.36279C18.4062 4.43876 22.5953 6.6093 21.3473 8.9969C20.1209 11.3302 14.7922 7.15194 10.1581 7.71628C10.0388 7.72713 10.0279 7.90077 10.1473 7.93333C11.0698 8.16124 13.0558 8.7907 15.7473 10.4946C18.9705 12.524 21.6295 12.7953 23.6915 10.7767C23.8109 10.6574 24.0062 10.7116 24.0496 10.8636C24.3752 11.9163 24.5488 13.045 24.5271 14.2171C24.4186 19.8822 19.7519 24.4837 14.0868 24.5271Z"
      fill="url(#paint0_linear_597_323)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_597_323"
        x1={2.85068}
        y1={5.5316}
        x2={25.1493}
        y2={22.4684}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1746} stopColor="#07AFFE" />
        <stop offset={0.8783} stopColor="#BEC9D1" />
      </linearGradient>
    </defs>
    </Svg>
  );
};

export default Icon;
