import { bscTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { CAKE_BNB_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: bscTokens.ermd,
    token1: bscTokens.wbnb,
    lpAddress: '0xDD616a83a5096507Ad17AcE1da1f66Fc2636AAc6',
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'ERMD',
    lpAddress: '0xFDe54627Ef5663Bdb7AEFb4aB2173461e0676C4f',
    token: bscTokens.syrup,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'ERMD-BNB LP',
    lpAddress: CAKE_BNB_LP_MAINNET,
    token: bscTokens.ermd,
    quoteToken: bscTokens.wbnb,
    boosted: false,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
