import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const ICAKE = {
  [ChainId.BSC]: '0x33F59453A43f846f91898Cf988475004c627B63F',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.ZEDX]: '0x1c87e84566e48046BB930654A1fe94e99D21A4A1',
  [ChainId.ERAM]: '0x531e71D254BfFB66B46A361Cef834E518FED275D',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_VAULT = {
  [ChainId.BSC]: '0x8B4FC48ee015420e19E2689799560285f5f6721d',
  [ChainId.BSC_TESTNET]: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.ZEDX]: '0x5f6cc061A8377d9e18c43F44e4D147f825D600Bc',
  [ChainId.ERAM]: '0xaB387712BeaD1e4c031977F69230245e3AF79a8F',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_FLEXIBLE_SIDE_VAULT = {
  [ChainId.BSC]: '0x8f2EB53579A261d1db466aC211B263f096368f07',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x',
  [ChainId.ZEDX]: '0x58b97A8A8C9969f00b08803A123d03947d233B8d',
  [ChainId.ERAM]: '0x5bf03E9b88025cbb2E983a0A947210f1A22fB68d',
} as const satisfies ContractAddresses<SupportedChainId>
