import { eramTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { CAKE_ERAM_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  // {
  //   pid: 1,
  //   token0: eramTokens.weram,
  //   token1: eramTokens.eramc,
  //   lpAddress: '0xE62EBA225Abb0bc946261A5BDA2aaea7C5CC0A0d',
  //   feeAmount: FeeAmount.LOW,
  // },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'ERAM',
    lpAddress: '0x54BA4075cEb2E31Ed20E0Cc442EA8ca0f3479CCf',
    token: eramTokens.syrup,
    quoteToken: eramTokens.weram,
  },
  // {
  //   pid: 3,
  //   v1pid: 3,
  //   lpSymbol: 'ERMD-ZEDX LP',
  //   lpAddress: CAKE_ERAM_LP_MAINNET,
  //   token: eramTokens.weram,
  //   quoteToken: eramTokens.eram,
  //   boosted: false,
  // },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export const farmsFinished = [
  // '0x96165F5e73ED28Fe12d1560913aBC97708bE4Ad7',
  // '0x755ad9e117866D083828DfBd4D8F70EaAa3fE8BF',
  // '0x36477c434a1d721E7c9E3f87f9752885737f897c',
  // '0x2683FC29350d95E9efBD1727CE12463aF16CD267',
  // '0x67a7CDba1388578420b652FfB7512AA00b66467d',
  // '0xc976d6827d759F67901eC980dA8a39D07B4C6b06',
  // '0xf7B8746A711feD66bF0c3Fe1a92432099103A5C0',
  // '0x0F65c020CA545982c94cBa756f60FF49DC3D4C71',
  // '0xC8f9eb61C47Fddc7bB0B8f13A5b181D134674Ac5',
  // '0x8Dc17E1D340C676a932D3a2503360aaD1Db5658c',
  // '0xbee38441B2f55434Feaee16502258e3Aa7647293',
  // '0x5fEC9F7399507f7b2eb49c42C77Fa46ea63e0599',
  // '0x33aD4DFf9f4370ccf52C419D9C2BF7Bb2aebD8c5',
]

export default farms
