import { vars } from "@pancakeswap/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg
      viewBox="0 0 95 30"
      fill="none"
      {...props}
    >
       <path
      d="M15 0C6.72093 0 0 6.72093 0 15C0 23.2791 6.72093 30 15 30C23.2791 30 30 23.2791 30 15C30 6.72093 23.2791 0 15 0ZM15.093 26.2791C12.2442 26.3023 9.62791 25.2674 7.63954 23.5349C7.54651 23.4535 7.61628 23.314 7.73256 23.3372C11.3953 24.0698 19 24.3837 23.5698 16.0581C23.6047 16 23.5233 15.9419 23.4767 15.9884C22.0465 17.6744 16.7093 23.1977 9.52326 21.1047C1.0814 18.6512 3.90698 11 9.87209 13.3721C14.9767 15.407 17.9884 16.2093 20.907 14.9302C21.0349 14.8721 20.9767 14.6977 20.8488 14.7093C19.3837 14.8837 16.1163 14.8605 12.3372 12.1047C9.4186 10 6.93023 9.77907 5.31395 9.97674C5.12791 10 4.98837 9.80233 5.0814 9.63953C6.98837 6.11628 10.7093 3.72093 15 3.72093C16.6163 3.72093 18.1512 4.05814 19.5465 4.67442C19.7209 4.75581 24.2093 7.0814 22.8721 9.63953C21.5581 12.1395 15.8488 7.66279 10.8837 8.26744C10.7558 8.27907 10.7442 8.46512 10.8721 8.5C11.8605 8.74419 13.9884 9.4186 16.8721 11.2442C20.3256 13.4186 23.1744 13.7093 25.3837 11.5465C25.5116 11.4186 25.7209 11.4767 25.7674 11.6395C26.1163 12.7674 26.3023 13.9767 26.2791 15.2326C26.1628 21.3023 21.1628 26.2326 15.093 26.2791Z"
      fill="url(#paint0_linear_595_248)"
    />
    <path
      d="M47.2919 10.4884V6.82558H47.071H46.6756H35.2803V7.38372H35.4198C35.5012 7.4186 35.5942 7.43023 35.7105 7.44186C37.4547 7.81395 37.5826 7.97674 37.5942 9.79069C37.5942 9.94186 37.5942 10.1046 37.5942 10.2558C37.5942 12.1628 37.5942 14.0698 37.5942 15.9884C37.5942 17.8953 37.6059 19.8139 37.5942 21.7209C37.5942 21.7558 37.5942 21.8023 37.5942 21.8488V23.1628H38.1524H39.1756H46.664H47.2919V19.6163H47.0361H46.9198H46.664C46.664 19.6163 46.6407 19.8023 46.6407 19.8372C46.6175 19.9884 46.5826 20.1395 46.5593 20.2907C46.3966 21.2791 45.9547 21.8372 45.0012 21.9535C43.6989 22.1046 42.3733 22.0465 41.0593 21.9767C40.5593 21.9535 40.2686 21.6395 40.2686 21.093C40.2686 19.2558 40.257 17.4302 40.2454 15.593C40.2454 15.2907 40.3617 15.1977 40.6524 15.1977C41.4663 15.2093 42.2803 15.1977 43.0942 15.2093C44.3966 15.2209 44.9547 15.686 45.1524 16.9767C45.164 17.0349 45.1873 17.3372 45.1873 17.3372H45.8849V12.1046H45.1873C45.1873 12.1046 45.1524 12.3837 45.1407 12.4419C45.1291 12.5349 45.071 12.6163 45.0477 12.7093C44.7454 13.7326 44.3384 14.0465 43.2686 14.0698C42.3966 14.0814 41.5245 14.0698 40.6524 14.0814C40.35 14.0814 40.2454 14 40.2454 13.686C40.257 12.1395 40.2454 10.6046 40.2686 9.05813C40.2803 8.26744 40.5477 8.03488 41.3268 8.03488C42.1059 8.03488 42.8733 8.03488 43.6524 8.03488C45.3617 8.03488 45.8733 8.44186 46.2454 10.1163C46.2919 10.3488 46.3733 10.4767 46.6524 10.4767C46.664 10.4767 46.664 10.4767 46.6756 10.4767V10.5116H47.2919V10.4884Z"
      fill="#06ADFD"
    />
    <path
      d="M74.0234 23.1977H76.5466L70.5931 6.83721H65.9885V7.41861C65.9885 7.41861 68.1862 7.32558 67.849 8.74419L62.6396 23.1977H64.1396L65.9769 18.1977H72.128L74.0234 23.1977ZM66.4187 17.0116L69.0117 9.95349L71.6862 17.0116H66.4187Z"
      fill="#BFCAD2"
    />
    <path
      d="M94.883 23.1977V6.80231H91.5691L86.8714 20.1628L82.1039 6.8372H76.5342V7.44185C76.5342 7.44185 78.9295 7.26743 78.9412 9.41859V23.1977H80.4644V8.95348L85.6039 23.1977H87.2784L92.383 8.74417V23.1977H94.883Z"
      fill="#BFCAD2"
    />
    <path
      d="M57.4539 15.2558C57.4539 15.2558 61.2562 14.8953 61.1051 11.186C60.9423 7.47674 58.012 6.82558 53.8958 6.82558H48.3027V7.37209C48.3027 7.37209 50.6167 7.24418 50.6283 9.37209V23.1977H53.3027V15.6395H54.8027L59.1051 23.1977H61.9074L57.4539 15.2558ZM53.2911 14.5V9.17441C53.2911 7.95348 54.4772 7.98837 54.4772 7.98837H55.0818C56.0237 7.98837 58.419 8.09302 58.419 11.3023C58.419 14.5116 55.2911 14.4884 55.2911 14.4884H53.2911V14.5Z"
      fill="#06ADFD"
    />
    <defs>
      <linearGradient
        id="paint0_linear_595_248"
        x1={3.0543}
        y1={5.92671}
        x2={26.9457}
        y2={24.0733}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1746} stopColor="#07AFFE" />
        <stop offset={0.8783} stopColor="#BEC9D1" />
      </linearGradient>
    </defs>
    </Svg>
  );
};

export default Logo;
