import { ChainId, ERC20Token } from '@pancakeswap/sdk'
import {
  bscTestnetTokens,
  bscTokens,
  ethereumTokens,
  goerliTestnetTokens,
  zkSyncTestnetTokens,
  polygonZkEvmTokens,
  zksyncTokens,
  zedxTokens,
} from '@pancakeswap/tokens'
import type { CommonPrice } from '../../src/fetchFarmsV3'
import type { FarmV3SupportedChainId } from '../../src'

export const CAKE_BNB_LP_MAINNET = '0x16DB5D50A704f80429155112173aFa9799bf2aD4'
export const CAKE_ZEDX_LP_MAINNET = '0xFC23e49277A81FAe832f72E61ABCA9aa405f1248'
export const CAKE_ERAM_LP_MAINNET = '0x5d28641a938d6996E76Af264D71Dc6B013B779D4'

export type PriceHelper = {
  chain: string
  list: ERC20Token[]
}

export const CHAIN_ID_TO_CHAIN_NAME = {
  [ChainId.BSC]: 'bsc',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.GOERLI]: 'ethereum',
  [ChainId.BSC_TESTNET]: 'bsc',
  [ChainId.POLYGON_ZKEVM]: 'polygon_zkevm',
  [ChainId.ZKSYNC]: 'era',
  [ChainId.POLYGON_ZKEVM_TESTNET]: '',
  [ChainId.ZKSYNC_TESTNET]: '',
  [ChainId.ZEDX]: 'zedx',
  [ChainId.ERAM]: 'eram',
} satisfies Record<FarmV3SupportedChainId, string>

export const priceHelperTokens = {
  [ChainId.ETHEREUM]: {
    chain: 'ethereum',
    list: [ethereumTokens.weth, ethereumTokens.usdc, ethereumTokens.usdt],
  },
  [ChainId.BSC]: {
    chain: 'bsc',
    list: [
      bscTokens.wbnb, 
      // bscTokens.usdt, 
      // bscTokens.busd, 
      bscTokens.eth
    ],
  },
  [ChainId.POLYGON_ZKEVM]: {
    chain: 'polygon_zkevm',
    list: [polygonZkEvmTokens.weth, polygonZkEvmTokens.usdc, polygonZkEvmTokens.usdt, polygonZkEvmTokens.matic],
  },
  [ChainId.ZKSYNC]: {
    chain: 'zksync',
    list: [zksyncTokens.weth, zksyncTokens.usdc, zksyncTokens.usdt],
  },
  [ChainId.ZEDX]: {
    chain: 'zedx',
    // list: [zedxTokens.wzedx, zedxTokens.usdt, zedxTokens.usdc],
    list: []
  },
  [ChainId.ERAM]: {
    chain: 'eram',
    list: []
  },
} satisfies Record<number, PriceHelper>

// for testing purposes
export const DEFAULT_COMMON_PRICE: Record<FarmV3SupportedChainId, CommonPrice> = {
  [ChainId.ETHEREUM]: {},
  [ChainId.GOERLI]: {
    [goerliTestnetTokens.mockA.address]: '10',
  },
  [ChainId.BSC]: {},
  [ChainId.BSC_TESTNET]: {
    [bscTestnetTokens.mockA.address]: '10',
    [bscTestnetTokens.usdt.address]: '1',
    [bscTestnetTokens.busd.address]: '1',
    [bscTestnetTokens.usdc.address]: '1',
  },
  [ChainId.ZKSYNC_TESTNET]: {
    [zkSyncTestnetTokens.mock.address]: '10',
  },
  [ChainId.POLYGON_ZKEVM]: {},
  [ChainId.ZKSYNC]: {},
  [ChainId.POLYGON_ZKEVM_TESTNET]: {},
  [ChainId.ZEDX]: {
    // [zedxTokens.usdt.address]: '1',
    // [zedxTokens.usdc.address]: '1',
  },
  [ChainId.ERAM]: {

  }
}
