import { vars } from "@pancakeswap/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const LogoFooter: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const id = Math.floor(Math.random() * 1000);

  return (
    <Svg viewBox="0 0 50 50" width="50" height="50"  fill="none" >
      <path
      d="M25 0C11.2016 0 0 11.2016 0 25C0 38.7985 11.2016 50 25 50C38.7985 50 50 38.7985 50 25C50 11.2016 38.7985 0 25 0ZM25.155 43.7985C20.407 43.8372 16.0465 42.1124 12.7326 39.2248C12.5775 39.0891 12.6938 38.8566 12.8876 38.8953C18.9922 40.1163 31.6667 40.6395 39.2829 26.7636C39.3411 26.6667 39.2054 26.5698 39.1279 26.6473C36.7442 29.4574 27.8488 38.6628 15.8721 35.1744C1.80233 31.0853 6.51163 18.3333 16.4535 22.2868C24.9612 25.6783 29.9806 27.0155 34.845 24.8837C35.0581 24.7868 34.9612 24.4961 34.7481 24.5155C32.3062 24.8062 26.8605 24.7674 20.562 20.1744C15.6977 16.6667 11.5504 16.2985 8.85659 16.6279C8.54651 16.6667 8.31395 16.3372 8.46899 16.0659C11.6473 10.1938 17.8488 6.20155 25 6.20155C27.6938 6.20155 30.2519 6.76357 32.5775 7.7907C32.8682 7.92636 40.3488 11.8023 38.1202 16.0659C35.9302 20.2326 26.4147 12.7713 18.1395 13.7791C17.9264 13.7984 17.907 14.1085 18.1202 14.1667C19.7674 14.5736 23.314 15.6977 28.1202 18.7403C33.876 22.3643 38.624 22.8488 42.3062 19.2442C42.5194 19.031 42.8682 19.1279 42.9457 19.3992C43.5271 21.2791 43.8372 23.2946 43.7985 25.3876C43.6047 35.5039 35.2713 43.7209 25.155 43.7985Z"
      fill={"url(#pattern0"+ id +")"}
    />
    <defs>
      <linearGradient
        id={"pattern0" + id}
        x1={5.0905}
        y1={9.87785}
        x2={44.9095}
        y2={40.1222}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.1746} stopColor="#07AFFE" />
        <stop offset={0.8783} stopColor="#BEC9D1" />
      </linearGradient>
    </defs>
    </Svg>

  );
};

export default LogoFooter;
