import useSWRImmutable from 'swr/immutable'

export const useCakePrice = () => {
  return useSWRImmutable(
    ['cake-usd-price'],
    async () => {
      const ermd = await (await fetch('https://eramdex.com/api/farms/price/cake')).json()
      return ermd.price as string
      // const ermd = await(await fetch('https://openapi.zedcex.com/open/api/get_ticker?symbol=eramusdt')).json()
      // return ermd.data.last as string
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}
