import { vars } from "@pancakeswap/ui/css/vars.css";
import { useIsMounted } from "@pancakeswap/hooks";
import React from "react";
import { Box, Flex } from "../Box";
import { Link } from "../Link";
import {
  StyledFooter,
  StyledIconMobileContainer,
  StyledLink,
  StyledLinkFlex,
  StyledList,
  StyledListItem,
  StyledSocialLinks,
  StyledText,
  StyledToolsContainer,
} from "./styles";

import { Button } from "../Button";
import CakePrice from "../CakePrice/CakePrice";
import LangSelector from "../LangSelector/LangSelector";
import {
  ArrowForwardIcon,
  LogoWithTextIcon,
  LogoFooter,
  LogoIcon,
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  DiscordIcon,
  MediumIcon,
  LinkedInIcon,
  RedditIcon,
  GithubIcon,
  ZedscanIcon,
  ZedbridgeIcon,
  ZedStakeIcon,
  ZedtokenIcon,
  ZeddropIcon
} from "../Svg";
import { ThemeSwitcher } from "../ThemeSwitcher";
import { FooterProps } from "./types";
import { SkeletonV2 } from "../Skeleton";
import { Text } from "../Text";
import Image from "next/image";
import NeoBankIcon from "../Svg/Icons/NeoBankIcon";
import ZedXionIcon from "../Svg/Icons/ZedXionIcon";
import ZedCexIcon from "../Svg/Icons/ZedCexIcon";

const MenuItem: React.FC<React.PropsWithChildren<FooterProps>> = ({
  items,
  isDark,
  toggleTheme,
  currentLang,
  langs,
  setLang,
  cakePriceUsd,
  buyCakeLabel,
  buyCakeLink,
  chainId,
  ...props
}) => {
  const isMounted = useIsMounted();

  return (
    <StyledFooter
      // data-theme="light"
      p={["40px 16px", null, "56px 40px 32px 40px"]}
      position="relative"
      {...props}
      justifyContent="center"
    >
      <Flex flexDirection="column" width={["100%", null, "1200px;"]}>
        <Flex style={{ gap: "30px" }} flexWrap="wrap">
          <Flex flexDirection="column" flex={["auto", null, 3]}>
            <Flex style={{ gap: "20px" }} alignItems="center" mb={30} className="citWur">
              <LogoFooter width="50px" />
              <Text fontSize={18} fontWeight={700}>
                Power by ERAM Smart Chain
              </Text>
            </Flex>
            <Text fontSize={14} mb={30}>
              EramDex facilitates decentralized trading of digital assets by the central bank, ensuring security, transparency, peer-to-peer transactions, and user control for reliable and guaranteed banking operations.
            </Text>
            <Flex flexWrap="wrap" style={{ gap: "20px" }} alignItems="center" mb={24}>
              {/* <StyledLinkFlex href="https://ermd-pay.com/" external>
                <NeoBankIcon />
                <Text fontSize={14}>NeoBank(ZedPay)</Text>
              </StyledLinkFlex>
              <StyledLinkFlex href="https://www.eramdex.com/en_US/" external>
                <ZedXionIcon />
                <Text fontSize={14}>CEX1(ERAM)</Text>
              </StyledLinkFlex> */}
              {/* <StyledLinkFlex href="https://www./en_US/market" external>
                <ZedCexIcon />
                <Text fontSize={14}>CEX2(ZedCex)</Text>
              </StyledLinkFlex> */}
              <StyledLinkFlex href="https://eramscan.com/" external>
                <ZedscanIcon />
                <Text fontSize={14}>EramScan</Text>
              </StyledLinkFlex>
              <StyledLinkFlex href="https://erambridge.com/" external>
                <ZedbridgeIcon />
                <Text fontSize={14}>EramBridge</Text>
              </StyledLinkFlex>
              <StyledLinkFlex href="https://eramstake.com/" external>
                <ZedStakeIcon />
                <Text fontSize={14}>EramStake</Text>
              </StyledLinkFlex>
              <StyledLinkFlex>
              </StyledLinkFlex>
              <StyledLinkFlex>
              </StyledLinkFlex>
              {/* <StyledLinkFlex href="https://token.zedxion.com/" external>
                <ZedtokenIcon />
                <Text fontSize={14}>ZedToken</Text>
              </StyledLinkFlex>
              <StyledLinkFlex href="https://airdrop.zedxion.com/" external>
                <ZeddropIcon />
                <Text fontSize={14}>ZedDrop</Text>
              </StyledLinkFlex> */}
            </Flex>
            <Flex style={{ gap: "20px" }} alignItems="center">
              <Link href="https://www.facebook.com/zedxioncoin">
                <FacebookIcon height={16} />
              </Link>
              <Link href="https://twitter.com/zedxionc">
                <TwitterIcon height={16} />
              </Link>
              <Link href="https://www.instagram.com/zedxion/">
                <InstagramIcon height={16} />
              </Link>
              <Link href="https://discord.com/invite/UDDb7FZF">
                <DiscordIcon height={16} />
              </Link>
              <Link href="https://medium.com/subscribe/@zedxion_exchange">
                <MediumIcon height={20} />
              </Link>
              <Link href="https://www.linkedin.com/company/zedxion-exchnage-crypto">
                <LinkedInIcon height={16} />
              </Link>
              <Link href="https://github.com/Zedxioncryptocurrency">
                <GithubIcon height={16} />
              </Link>
            </Flex>
          </Flex>
          <Flex flexDirection="column" flex={["auto", null, 1]}>
            <Text fontSize={16} fontWeight={700} mb={24}>
              Company
            </Text>
            <StyledLink href="#" mb={12}>
              Delegate to EramScan
            </StyledLink>
            <StyledLink href="#" mb={20}>
              <Text
                as={"span"}
                style={{ background: "#08B0FF", padding: "4px 8px", borderRadius: "13px" }}
                color="white"
              >
                Staking
              </Text>
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Brand Assets
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Contact Us
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Terms & Privacy
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Bug Bounty
            </StyledLink>
          </Flex>
          <Flex flexDirection="column" flex={["auto", null, 1]}>
            <Text fontSize={16} fontWeight={700} mb={24}>
              Community
            </Text>
            <StyledLink href="#" mb={20}>
              API Documentation
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Knowledge Base
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Network Status
            </StyledLink>
            <StyledLink href="https://docs.zedscan.net/general/learn-about-the-fuse-platform" mb={20} external>
              Learn ERAM
            </StyledLink>
          </Flex>
          <Flex flexDirection="column" flex={["auto", null, 1]}>
            <Text fontSize={16} fontWeight={700} mb={24}>
              Products & Services
            </Text>
            <StyledLink href="#" mb={20}>
              Advertise
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Explorer-as-a-Service (EaaS)
            </StyledLink>
            <StyledLink href="#" mb={20}>
              API Plans
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Priority Support
            </StyledLink>
            <StyledLink href="#" mb={20}>
              Blockscan
            </StyledLink>
          </Flex>
        </Flex>
        <Flex py="30px" style={{ gap: "20px" }}>
          <Text fontSize={13}>ERAM Chain @ 2024</Text>
          <Text fontSize={13}>|</Text>
          <StyledLink href="https://docs.zedscan.net/zedxion-whitepaper/" style={{ color: "#08B0FF" }} external>
            WhitePaper
          </StyledLink>
        </Flex>
        {/* <StyledIconMobileContainer display={["block", null, "none"]}>
          <LogoFooter width="118px" />
        </StyledIconMobileContainer>
        <Flex
          order={[2, null, 1]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={["42px", null, "36px"]}
        >
          {items?.map((item) => (
            <StyledList key={item.label}>
              <StyledListItem>{item.label}</StyledListItem>
              {item.items?.map(({ label, href, isHighlighted = false }) => (
                <StyledListItem key={label}>
                  {href ? (
                    <Link
                      data-theme="dark"
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener"
                      color={isHighlighted ? vars.colors.white : "white"}
                      bold={false}
                    >
                      {label}
                    </Link>
                  ) : (
                    <StyledText>{label}</StyledText>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          ))}
          <Box display={["none", null, "block"]}>
            <LogoFooter width="225px" />
          </Box>
        </Flex>
        <StyledSocialLinks order={[2]} pb={["42px", null, "32px"]} mb={["0", null, "32px"]} />
        <StyledToolsContainer
          data-theme="dark"
          order={[1, null, 3]}
          flexDirection={["column", null, "row"]}
          justifyContent="space-between"
        >
          <Flex order={[2, null, 1]} alignItems="center">
            <SkeletonV2 variant="round" width="56px" height="32px" isDataReady={isMounted}>
              <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
            </SkeletonV2>
            <LangSelector
              currentLang={currentLang}
              langs={langs}
              setLang={setLang}
              color="textSubtle"
              dropdownPosition="top-right"
            />
          </Flex>
          <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center">
            <Box mr="20px">
              <CakePrice chainId={chainId} cakePriceUsd={cakePriceUsd} color="white" />
            </Box>
            <Button
              data-theme={isDark ? "dark" : "light"}
              as="a"
              href={buyCakeLink}
              target="_blank"
              scale="sm"
              // endIcon={<ArrowForwardIcon color="backgroundAlt" />}
            >
              {buyCakeLabel}
            </Button>
          </Flex>
        </StyledToolsContainer> */}
      </Flex>
    </StyledFooter>
  );
};

export default MenuItem;
