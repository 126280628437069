import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("Social Media"),
    items: [
      {
        label: t("Gchain Twitter"),
        href: "https://twitter.com/ERAMChain",
      },
      {
        label: t("EramDex Twitter"),
        href: "https://twitter.com/BlueLotusZEDX",
      },
      {
        label: t("EramDex View app Twitter"),
        href: "https://twitter.com/GvaApp",
      },
      {
        label: t("ERAM Network Discord"),
        href: "https://discord.gg/XjHDMXQTjT",
      },
      {
        label: t("Blue Hosting Twitter"),
        href: "https://twitter.com/BlueHostingGsys",
      },
      {
        label: t("ERAM Network Telegram"),
        href: "https://web.telegram.org/a/#-1619157049",
      },
    ],
  },
  {
    label: t("ERAM Network Ecosystem"),
    items: [
      {
        label: t("ERAM Faucet"),
        href: "https://faucet.ERAM.network/",
      },
      {
        label: t("Gchain Testnet"),
        href: "https://testnet-zedscan.net/",
      },
      {
        label: t("Blue Hosting"),
        href: "https://www.bluehostinzedx.com/en/",
      },
      {
        label: t("ERAM View app"),
        href: "https://www.ERAMviewapp.io/",
      },
      {
        label: t("ERAM Web Wallet"),
        href: "https://ERAMwallet.io/#/",
      },
      {
        label: t("EramDex"),
        href: "https://www.eramdex.com/",
      },
      {
        label: t("Genesis of Blue NFT Marketplace"),
        href: "https://www.genesisofblue.io/",
      },
    ],
  },
  {
    label: t("About Gchain"),
    items: [
      {
        label: "Contact Us",
        href: "https://helpdesk.ERAM.network/",
      },
      {
        label: "Network Status",
        href: "https://status.ERAM.network/",
      },
      {
        label: "API Documentation",
        href: "https://docs.ERAM.network/",
      },
      {
        label: "Contact us for banners",
        href: "https://zedscan.net/address/0xBCeE7f35DD80f232a9123F903831019D8bAa9D91#",
      },
      {
        label: "ERAM Network Whitepaper",
        href: "https://genesyblockchain.gitbook.io/ERAM-blockchain",
      },
      {
        label: "Brand Assets",
        href: "https://genesyblockchain.gitbook.io/ERAM-blockchain/blue-lotus-governance/brand-and-logo",
      },
      {
        label: "Blockscan",
        href: "https://zedscan.net/address/0xBCeE7f35DD80f232a9123F903831019D8bAa9D91#",
      },
      {
        label: "Terms of Service",
        href: "https://genesyblockchain.gitbook.io/ERAM-blockchain/ERAM-blockchain-gchain/gchain-terms-and-conditions",
      },
    ],
  },
];
