import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | EramDex',
  defaultTitle: 'EramDex',
  description:
    'Cheaper and faster than Uniswap? Discover EramDex, the leading DEX on ERAM Smart Chain (ERAM) with the best farms in DeFi and a lottery for ERMD.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@EramDex',
    site: '@EramDex',
  },
  openGraph: {
    title: 'EramDex - A next evolution DeFi exchange on ERAM Smart Chain (ERAM)',
    description:
      'EramDex part of ERAM Network Ecosystem, earn ERMD through farming, staking or lottery,  swap your tokens with security and confidence.',
    // images: [{ url: process.env.NEXT_PUBLIC_URL + '/images/hero.png?v=1.0' }],
  },
}
